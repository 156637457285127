import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import { UseIsMountedExample } from './use-is-mounted.example.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "useismounted"
    }}>{`useIsMounted`}</h1>
    <p>{`Use this hook to determine if a component is still mounted or not. This is
probably an anti-pattern but can be useful when trying to use promises inside a
hook to not risk setting state on an unmounted component.`}</p>
    <p><em parentName="p">{`Using promises is not ideal with hooks. And it will probably be a whole lot
easier once the React team releases Concurrent react with Suspense and all that
stuff.`}</em></p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React, { useState } from 'react';
import { useIsMounted } from '@fransvilhelm/hooks';

const Username = ({ userId }) => {
  const isMounted = useIsMounted();
  const [username, setUsername] = useState('');

  useEffect(() => {
    fetch(\`/get-username/\${userId}\`)
      .then((res) => res.json())
      .then((json) => {
        // When we arrive here the component might have already been
        // unmounted. Therefore we have to guard against that.
        if (isMounted()) setUsername(json.username);
      });
  }, []);

  return <p>Username: {username}</p>;
};
`}</code></pre>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <p><strong parentName="p">{`NOTE`}</strong>{`: The above example will not work with a dynamic `}<inlineCode parentName="p">{`userId`}</inlineCode>{`. See
`}<a parentName="p" {...{
        "href": "/hook/use-promise"
      }}><inlineCode parentName="a">{`usePromise`}</inlineCode></a>{` for a better version.`}</p>
    <Playground __position={0} __code={'<UseIsMountedExample />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      UseIsMountedExample,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UseIsMountedExample mdxType="UseIsMountedExample" />
    </Playground>
    <h2 {...{
      "id": "parameters"
    }}>{`Parameters`}</h2>
    <p><inlineCode parentName="p">{`useIsMounted`}</inlineCode>{` no argument`}</p>
    <h2 {...{
      "id": "returns"
    }}>{`Returns`}</h2>
    <p><inlineCode parentName="p">{`useIsMounted`}</inlineCode>{` returns a a function, which when called returns a boolean
indicating if the component is mounted or not.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Returns`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`isMounted`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`() => boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Function that when called returns if the component is updated or not`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      